import * as React from "react";
import { CogIcon } from "@heroicons/react/solid";
import PageTitle from "../components/elements/PageTitle";
import ActionCallSection from "../components/sections/ActionCallSection";
import OurProcessSection from "../components/sections/OurProcessSection";
import TestimonialsSection from "../components/sections/TestimonialsSection";
import Footer from "../components/sections/Footer";
import TestimonialItem from "../components/elements/TestimonialItem";
import david from "../images/david.jpg";
import girl from "../images/cheerful-curly-business-girl-wearing-glasses_176420-206 копия.jpg";
import douglas from "../images/douglas.jpg";
import ShortContactFormSection from "../components/sections/ShortContactFormSection";
import ActionCallItem from "../components/elements/ActionCallItem";
import TwoColumnsSection from "../components/sections/TwoColumnsSection";
import MetricsSection from "../components/sections/MetricsSection";
import MetricItem from "../components/elements/MetricItem";

const IndexPage = () => (
  <div className="bg-white">
    <PageTitle
      pageTitle={"Home"}
      description={`IT grows develops software on different platforms and programming languages that may cover different business sides. IT grows has experience with customers from many countries over 7 years.`}
    />
    <ActionCallSection
      title="We grow your IT"
      submitButton={"Get more info"}
      submitButtonSuccess={"Sent"}
      sentenceLinkPath={"/en/contacts/"}
    >
      <ActionCallItem
        name="Developers"
        Icon={CogIcon}
        description="We work with a wide network of developers: employees and freelancers; each developer passes an internal competency assessment, which allows us to guarantee high quality of development."
        linkText="Contact us"
        linkPath="/en/contacts"
      />
      <ActionCallItem
        name="Expertise and consulting"
        Icon={CogIcon}
        description="The company has rich experience in creating web and mobile applications, and we offer you the best solutions according to the latest trends in development and architecture."
        linkText="Contact us"
        linkPath="/en/contacts"
      />
      <ActionCallItem
        name="Tools and processes"
        Icon={CogIcon}
        description="The company uses standardized processes and tools to start the development process immediately. We provide the customer with development and project management tools."
        linkText="Contact us"
        linkPath="/en/contacts"
      />
    </ActionCallSection>
    {/* <IndustriesSection
      sectionTitle={"Industries"}
      // title={"Industries"}
      description={``}
    >
      <IndustryItem
        imgFileName={"doctor-monitoring-the-patient-s-pulse_23-2149372307.jpg"}
        name={"Healthcare"}
      />
      <IndustryItem
        imgFileName={
          "close-up-of-cards-servicing-with-pos-terminal-isolated-on-white-background-female-hand-with-credit-card-and-bank-terminal_1391-260.jpg"
        }
        name={"POS"}
      />
      <IndustryItem
        imgFileName={
          "sideways-business-man-calculating-finance-numbers_23-2148793752.jpg"
        }
        name={"Finance"}
      />
      <IndustryItem
        imgFileName={"man-using-a-tablet-in-his-smart-home_23-2149036896.jpg"}
        name={"Hi-Tech"}
      />
      <IndustryItem
        imgFileName={
          "save-the-world-ecology-environmental-conservation-perforated-paper-recycle_53876-31051.jpg"
        }
        name={"CleanTech"}
      />
      <IndustryItem
        imgFileName={
          "robot-handshake-human-background-artificial-intelligence-digital-transformation_53876-129769.jpg"
        }
        name={"AI"}
      />
    </IndustriesSection> */}
    {/* <ServicesSection
      sectionTitle={"Making your business grow"}
      title={"Services we provide"}
      description={`From MVP development to post-release support, we help our customers bring their products to market faster and with no barries on the way.`}
      showMoreText={"Show more services"}
      showMorePath={"/en/services/"}
    >
      <ServiceItem
        name="IT consulting"
        description={`IT grows helps companies in different industries create optimal digital environments corresponding to their technical capabilities, business requirements, needs of employees and clients expectations.`}
        IconImg={CogIcon}
      />
      <ServiceItem
        name="Software development"
        description={`The company develops software for any-sized business and enterprizes. Our offering includes a wide range of software development services to meet the various business needs of our customers and technological goals.`}
        IconImg={CubeTransparentIcon}
      />
      <ServiceItem
        name="Product Development"
        description={`We provide a product development service from scratch, build a complete development strategy that looks like plan which lists all the stages involved in the process of software development.`}
        IconImg={ServerIcon}
      />
      <ServiceItem
        name="QA and testing"
        description={`We will test the functionality of your software according to the requirements and performance assessments. The functional tests will verify how well the whole system functions.`}
        IconImg={CubeTransparentIcon}
      />
      <ServiceItem
        name="DevOps"
        description={`IT grows establishes from scratch the delivery of software in accordance with DevOps methodologies as well as helps companies improve existing processes, whether it’s development, deployment or QA and testing.`}
        IconImg={CubeTransparentIcon}
      />
      <ServiceItem
        name="Smart Teams"
        description={`Our developers have mastered technologies and platforms, which makes them qualified to perform very complex projects in different fields, allowing flexibility to respond to your needs.`}
        IconImg={CogIcon}
      />
    </ServicesSection> */}
    {/* <SolutionsSection
      title={"Making success stories for over 7 years"}
      description={`We collect your requirements, the latest trends in the world of technology and our best practices to come up with a solution that will satisfy you.`}
    >
      <SolutionItem name={"Laravel"} IconImg={pinlogo} />
      <SolutionItem name={"Laravel"} IconImg={barlogo} />
      <SolutionItem name={"StaticKit"} IconImg={wallylogo} />
      <SolutionItem name={"Statamic"} IconImg={inslogo} />
    </SolutionsSection> */}
    <MetricsSection
      sectionTitle="Valuable Metrics"
      title=""
      description=""
      img="https://images.unsplash.com/photo-1521737852567-6949f3f9f2b5?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2830&q=80&sat=-100"
    >
      <MetricItem
        stat={`40+`}
        emphasis={`Developers`}
        rest={`employed and verified freelancers`}
      />
      <MetricItem
        stat={`100+`}
        emphasis={`Completed projects`}
        rest={`of web- and mobile application development`}
      />
      <MetricItem
        stat={`4+`}
        emphasis={`Countries`}
        rest={`covered: USA, Sweden, Russia and Denmark`}
      />
      <MetricItem
        stat={`6+`}
        emphasis={`Years`}
        rest={`of MVP and Enteriprise development`}
      />
    </MetricsSection>
    <OurProcessSection
      title={"OUR PROCESSES"}
      description={"How do we improve the development process?"}
      articleTitle={`Our process`}
      // imgFileName OR imgUrl
      imgFileName={""}
      imgUrl={
        "https://images.unsplash.com/photo-1522071820081-009f0129c71c?ixlib=rb-1.2.1&auto=format&fit=crop&w=1567&q=80"
      }
    />
    <TwoColumnsSection
      title={""}
      description={"How do we meet deadlines?"}
      articleTitle={`Deadlines`}
      linkText="Contact sales"
      linkPath="/en/contacts"
    />
    {/*<TestimonialsSection
      sectionTitle={"What clients say about us"}
      title={""}
      // description={`Nullam risus blandit ac aliquam justo ipsum. Quam mauris volutpat massa dictumst amet. Sapien tortor lacus arcu.`}
    >
      <TestimonialItem
        title={"subject"}
        description={"////"}
        name={"Douglas Oest"}
        authorImg={douglas}
        date={"May 19, 2022"}
        datetime={"2022-05-19"}
      />
      <TestimonialItem
        title={"subject"}
        description={"/////"}
        name={"Joakim Klintred"}
        authorImg={girl}
        date={"Jan 10, 2022"}
        datetime={"2022-01-10"}
      />
      <TestimonialItem
        title={"subject"}
        description={"////"}
        name={"David Hamren"}
        authorImg={david}
        date={"Mar 16, 2021"}
        datetime={"2021-03-16"}
      />
    </TestimonialsSection>*/}
    <ShortContactFormSection
      formTitle={"Contact us"}
      description={``}
      nameLabel={"First name"}
      lastNameLabel={"Last name"}
      emailLabel={"Email"}
      phoneLabel={"Phone"}
      submitButton={"Submit"}
      submitButtonSuccess={"Sent"}
    />
    <Footer />
  </div>
);

export default IndexPage;
