import { ChevronRightIcon } from "@heroicons/react/solid";
import { Link } from "gatsby";
import React from "react";

const MetricsSection = ({
  sectionTitle,
  title,
  description,
  img,
  children,
}) => (
  <div className="relative bg-gray-900">
    <div className="h-80 w-full absolute bottom-0 xl:inset-0 xl:h-full">
      <div className="h-full w-full xl:grid xl:grid-cols-2">
        <div className="h-full xl:relative xl:col-start-2">
          <img
            className="h-full w-full object-cover opacity-25 xl:absolute xl:inset-0"
            src={img}
            alt="People working on laptops"
          />
          <div
            aria-hidden="true"
            className="absolute inset-x-0 top-0 h-32 bg-gradient-to-b from-gray-900 xl:inset-y-0 xl:left-0 xl:h-full xl:w-32 xl:bg-gradient-to-r"
          />
        </div>
      </div>
    </div>
    <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8 xl:grid xl:grid-cols-2 xl:grid-flow-col-dense xl:gap-x-8">
      <div className="relative pt-12 pb-64 sm:pt-24 sm:pb-64 xl:col-start-1 xl:pb-24">
        {sectionTitle && (
          <h2 className="text-sm font-semibold text-amber-500 tracking-wide uppercase">
            {sectionTitle}
          </h2>
        )}
        {title && (
          <p className="mt-3 text-3xl font-extrabold text-white">{title}</p>
        )}
        {description && (
          <p className="mt-5 text-lg text-gray-300">{description}</p>
        )}
        <div className="mt-12 grid grid-cols-1 gap-y-12 gap-x-6 sm:grid-cols-2">
          {children}
        </div>
        <div className="inline-block mt-12 rounded-md shadow">
          <Link
            to="/en/portfolio/"
            className="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-amber-600 hover:bg-amber-600/90"
          >
            Go to portfolio{" "}
            <ChevronRightIcon
              className="flex-shrink-0 w-6 h-6 text-amber-400"
              aria-hidden="true"
            />
          </Link>
        </div>
      </div>
    </div>
  </div>
);

export default MetricsSection;
