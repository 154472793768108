import * as React from "react";

const TestimonialItem = ({
  title,
  description,
  name,
  authorImg,
  date,
  datetime,
}) => (
  <div key={title}>
    <div className="block mt-4">
      <p className="text-xl font-semibold text-blue-gray-900">{title}</p>
      <p className="mt-3 text-base text-gray-500">{description}</p>
    </div>
    <div className="mt-6 flex items-center">
      <div className="flex-shrink-0">
        <div>
          <span className="sr-only">{name}</span>
          <img className="h-10 w-10 rounded-full" src={authorImg} alt={name} />
        </div>
      </div>
      <div className="ml-3">
        <p className="text-sm font-medium text-blue-gray-900">{name}</p>
        <div className="flex space-x-1 text-sm text-gray-500">
          <time dateTime={datetime}>{date}</time>
        </div>
      </div>
    </div>
  </div>
);

export default TestimonialItem;
