import React, { useState, useEffect } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import ReactMarkdown from "react-markdown";

const TwoColumnsSection = ({
  title,
  description,
  articleTitle,
  linkText,
  linkPath,
}) => {
  const [markdown, setMarkdown] = useState({});

  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark {
        nodes {
          frontmatter {
            title
          }
          excerpt(format: MARKDOWN, pruneLength: 2147483647)
        }
      }
    }
  `);

  useEffect(() => {
    setMarkdown(
      data.allMarkdownRemark.nodes.find((e) =>
        e.frontmatter.title.includes(articleTitle)
      )
    );
  }, [data]);

  return (
    <div className="px-8 sm:px-0 lg:px-8 overflow-hidden mb-8 bg-gray-900">
      <div className="max-w-prose lg:max-w-7xl mx-auto">
        <div className="relative z-10 mb-8 md:mb-2 pt-8">
          <div className="text-base max-w-prose lg:max-w-none text-right lg:text-left">
            <h2 className="leading-6 text-amber-600 font-semibold tracking-wide uppercase">
              {title}
            </h2>
            <p className="text-3xl leading-8 font-extrabold tracking-tight text-gray-100 sm:text-4xl">
              {description}
            </p>
          </div>
        </div>
        <div className="relative mt-5">
          <div className="z-11 relative mt-8 pb-8 prose prose-indigo text-gray-300 article">
            <div className="sm:columns-2 gap-x-[4%] ">
              <ReactMarkdown>{markdown.excerpt}</ReactMarkdown>
            </div>
            {linkText && (
              <div className="mt-8 inline-flex rounded-md shadow">
                <Link
                  href={linkPath}
                  className="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-amber-600 hover:bg-amber-600/90"
                >
                  {linkText}
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TwoColumnsSection;
