import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Header from "./Header";

const backgroundImagePath =
  "../../images/programming_background_with_person_working_with_codes_computer.jpg";

const ActionCallSection = ({ title, highlightedText, children }) => (
  <>
    <div className="relative pb-36 bg-blue-gray-800">
      <div className="absolute inset-0">
        <StaticImage
          src={backgroundImagePath}
          alt=""
          className="w-full h-full object-cover"
          imgClassName="w-full h-full object-cover"
          height={600}
          placeholder="none"
        />
        <div className="absolute inset-0 bg-blue-gray-800 mix-blend-multiply" />
      </div>

      <Header isACS={true} />

      <div className="relative max-w-md mx-auto px-4 mt-16 pb-28 sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8 text-center">
        <h1 className="mt-4 tracking-tighter font-extrabold lg:mt-6 text-3xl sm:text-5xl xl:text-6x">
          {title.split(" ").map((word, ind) => {
            if ((ind + 1) % 2 == 0) {
              return (
                <div className="inline uppercase text-amber-500">{word} </div>
              );
            } else return <div className="inline text-white">{word} </div>;
          })}
        </h1>
      </div>
    </div>

    <div className="bg-blue-gray-50 pb-16">
      {/* Cards */}
      <section
        className="-mt-32 mx-auto relative z-10 px-4 sm:px-6 lg:max-w-7xl lg:px-8"
        aria-labelledby="contact-heading"
      >
        <h2 className="sr-only" id="contact-heading">
          Contact us
        </h2>
        <div className="grid grid-cols-1 gap-y-20 gap-x-4 sm:grid-cols-3 lg:gap-x-8">
          {children}
        </div>
      </section>
    </div>
  </>
);

export default ActionCallSection;
