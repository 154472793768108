import React from "react";
import { Link } from "gatsby";

const ActionCallItem = ({ name, Icon, description, linkText, linkPath }) => (
  <div key={name} className="flex flex-col bg-white rounded-2xl shadow-xl">
    <div className="flex-1 relative pt-16 px-6 pb-8 md:px-8">
      <div className="absolute top-0 p-5 inline-block bg-[#f79e05] rounded-xl shadow-lg transform -translate-y-1/2">
        <Icon className="h-6 w-6 text-white" aria-hidden="true" />
      </div>
      <h3 className="text-xl font-medium text-blue-gray-900">{name}</h3>
      <p className="mt-4 text-base text-blue-gray-500">{description}</p>
    </div>
    <Link to={linkPath} className="p-6 bg-blue-gray-50 rounded-bl-2xl rounded-br-2xl md:px-8 bg-sky-100/[0.7] hover:bg-sky-100/[0.9]">
      <div to={linkPath} className="text-base font-medium text-sky-800">
        {linkText}
        <span aria-hidden="true"> &rarr;</span>
      </div>
    </Link>
  </div>
);

export default ActionCallItem;
