import React, { useState, useEffect, createRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { CheckIcon } from "@heroicons/react/solid";
import { initialFormFields } from "../utils/utils";
import { bearerToken, captchaInfoUrl, sendMailerUrl } from "../../api/mailer";

const ShortContactFormSection = ({
  description,
  formTitle,
  nameLabel,
  emailLabel,
  phoneLabel,
  submitButton,
  submitButtonSuccess,
}) => {
  const recaptchaRef = createRef();

  const [formFields, setFormFields] = useState(initialFormFields);
  const [formIsSent, setFormIsSent] = useState(false);

  const [siteKey, setSiteKey] = useState("");
  const [, setSiteUrl] = useState("");
  const [recaptchaResultKey, setRecaptchaResultKey] = useState();

  const [isVerified, setIsVerified] = useState(false);
  const [serverError, setServerError] = useState(null);

  const getCaptchaInfo = async () => {
    const resp = await fetch(captchaInfoUrl, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + bearerToken,
      },
    }).catch((err) => {
      setServerError("Something was wrong. Try again.");
      setFormIsSent(false);
    });

    const data = await resp.json();

    setSiteKey(data.site_key);
    setSiteUrl(data.site_url);
  };

  useEffect(() => {
    getCaptchaInfo();
  }, []);

  const setName = (event) => {
    setFormFields({ ...formFields, name: event.target.value });
  };
  const setEmail = (event) => {
    setFormFields({ ...formFields, email: event.target.value });
  };
  const setPhone = (event) => {
    setFormFields({ ...formFields, phone: event.target.value });
  };

  const onSubmit = () => {
    fetch(sendMailerUrl, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + bearerToken,
      },
      body: JSON.stringify({
        email: formFields.email,
        name: formFields.name,
        phone: formFields.phone,
        subject: "Join team",

        recaptcha: recaptchaResultKey,
      }),
    })
      .catch((err) => {
        new Error(err);
        setFormIsSent(false);
      })
      .then((res) => {
        setFormIsSent(true);
        setFormFields(initialFormFields);
      });
  };

  return (
    <div className="border-t border-gray-200 pt-8 mt-8">
      <div className="relative">
        <div
          className="absolute left-0 right-0 h-1/2 bg-warm-gray-50"
          aria-hidden="true"
        />
        <div className="relative max-w-md mx-auto px-4 sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
          <div className="py-10 px-6 bg-gradient-to-l from-amber-400 to-amber-500 rounded-3xl sm:py-10 sm:px-8 lg:py-6 lg:px-16 lg:flex lg:items-center">
            <div className="lg:w-0 lg:flex-1">
              <h2 className="text-3xl font-extrabold tracking-tight text-blue-gray-900">
                {formTitle}
              </h2>
              <p className="mt-4 max-w-3xl text-lg text-[#163a5f]">
                {description}
              </p>
            </div>
            <div className="sm:w-full lg:mt-0 lg:ml-8 lg:flex-1">
              {/* Contact form */}
              <form
                method="POST"
                onSubmit={onSubmit}
                className="mt-9 grid grid-cols-1 gap-y-2 sm:grid-cols-2 sm:gap-x-8"
              >
                <div>
                  <div className="flex justify-between">
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium text-[#163a5f]"
                    >
                      {nameLabel}
                    </label>
                    <span id="name" className="text-md text-red-500">
                      *
                    </span>
                  </div>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="name"
                      id="name"
                      autoComplete="given-name"
                      onChange={setName}
                      required
                      className="w-full shadow-md border-white px-5 py-3 placeholder-warm-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-amber-500 focus:ring-white rounded-md"
                    />
                  </div>
                </div>
                <div>
                  <div className="flex justify-between">
                    <label
                      htmlFor="phone"
                      className="block text-sm font-medium text-[#163a5f]"
                    >
                      {phoneLabel}
                    </label>
                    <span id="phone" className="text-md text-gray-500">
                      Optional
                    </span>
                  </div>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="phone"
                      id="phone"
                      autoComplete="tel"
                      onChange={setPhone}
                      className="w-full shadow-md border-white px-5 py-3 placeholder-warm-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-amber-500 focus:ring-white rounded-md"
                    />
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <div className="flex justify-between">
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-[#163a5f]"
                    >
                      {emailLabel}
                    </label>
                    <span id="email" className="text-md text-red-500">
                      *
                    </span>
                  </div>
                  <div className="mt-1">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      onChange={setEmail}
                      className="w-full shadow-md border-white px-5 py-3 placeholder-warm-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-amber-500 focus:ring-white rounded-md"
                    />
                  </div>
                </div>
                <div className="sm:flex sm:justify-end text-right sm:col-span-2 mt-2">
                  {siteKey &&
                    formFields.name &&
                    formFields.email &&
                    !isVerified && (
                      <ReCAPTCHA
                        ref={recaptchaRef}
                        badge={"inline"}
                        size={"normal"}
                        sitekey={siteKey}
                        onErrored={() => setIsVerified(false)}
                        onExpired={() => setIsVerified(false)}
                        onChange={() => {
                          setRecaptchaResultKey(
                            recaptchaRef.current.getValue()
                          );
                          setIsVerified(true);
                        }}
                      />
                    )}
                  {!isVerified && (!formFields.name || !formFields.email) && (
                    <button
                      type="button"
                      onClick={onSubmit}
                      disabled={!formFields.email || !formFields.name}
                      className="mt-3 w-full flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-[#163a5f] hover:bg-[#133252] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-cyan-700 focus:ring-amber-400 disabled:bg-gray-200 sm:mt-0 sm:ml-3 sm:w-auto sm:flex-shrink-0"
                    >
                      {submitButton}
                    </button>
                  )}
                  {isVerified && (
                    <>
                      {!formIsSent || !isVerified ? (
                        <>
                          {!formIsSent && serverError && (
                            <p className="text-red-800 mt-3">{serverError}</p>
                          )}
                          <button
                            type="button"
                            onClick={onSubmit}
                            disabled={!formFields.email || !formFields.name}
                            className="mt-3 w-full flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-[#163a5f] hover:bg-[#133252] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-cyan-700 focus:ring-amber-400 disabled:bg-gray-200 sm:mt-0 sm:ml-3 sm:w-auto sm:flex-shrink-0"
                          >
                            {submitButton}
                          </button>
                        </>
                      ) : (
                        <button
                          type="submit"
                          className="mt-3 w-full flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 bg-green-600 hover:bg-green-700 focus:ring-green-500 sm:mt-0 sm:ml-3 sm:w-auto sm:flex-shrink-0"
                        >
                          {submitButtonSuccess}
                          <CheckIcon className="h-6 w-6 ml-2 " />
                        </button>
                      )}
                    </>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShortContactFormSection;
