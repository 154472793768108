import * as React from "react";

const TestimonialsSection = ({
  sectionTitle,
  title,
  description,
  children,
}) => (
  <div className="bg-white pt-8 px-4 sm:px-6 lg:pt-8 lg:px-8 border-t border-gray-200 pt-4 mt-4 lg:mt-8">
    <div className="max-w-7xl mx-auto">
      <div>
        <h2 className="text-base font-semibold tracking-wider text-amber-600 uppercase text-center">
          {sectionTitle}
        </h2>
        <p className="mt-2 text-3xl font-extrabold text-blue-gray-900 tracking-tight sm:text-4xl text-center">
          {title}
        </p>
        {description ? (
          <p className="mt-5 max-w-prose mx-auto text-xl text-gray-500 text-center">
            {description}
          </p>
        ) : (
          ""
        )}
      </div>
      <div className="mt-8 grid gap-8 lg:gap-16 lg:grid-cols-3 lg:gap-x-5 lg:gap-y-12">
        {children}
      </div>
    </div>
  </div>
);

export default TestimonialsSection;
