import React, { useState, useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import ReactMarkdown from "react-markdown";

const OurProcessSection = ({
  title,
  description,
  articleTitle,
  imgFileName,
  imgUrl,
}) => {
  const [markdown, setMarkdown] = useState({});

  const [img, setImg] = useState("");
  const [imgNode, setImgNode] = useState({});

  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark {
        nodes {
          frontmatter {
            title
          }
          excerpt(format: MARKDOWN, pruneLength: 2147483647)
        }
      }
      allImageSharp {
        edges {
          node {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
              originalName
            }
          }
        }
      }
    }
  `);

  useEffect(() => {
    setMarkdown(
      data.allMarkdownRemark.nodes.find((e) =>
        e.frontmatter.title.includes(articleTitle)
      )
    );
    setImgNode(
      data.allImageSharp.edges.find((e) =>
        e.node.fluid.originalName.includes(imgFileName)
      )
    );
  }, [data]);

  useEffect(() => {
    if (imgNode && imgNode.node && imgNode.node.fluid) {
      setImg(imgNode.node.fluid);
    }
  }, [imgNode]);

  return (
    <div className="bg-white max-w-7xl mx-auto mb-8">
      <div className="pt-20 px-4 sm:pt-16 sm:px-6 lg:px-8">
        <div className="relative bg-white">
          <div className="lg:absolute lg:inset-0">
            <div className="lg:absolute lg:inset-y-0 lg:left-0 lg:w-1/2">
              {imgUrl ? (
                <img
                  className="h-56 w-full object-cover lg:absolute lg:h-full"
                  src={imgUrl}
                  alt=""
                />
              ) : (
                <>
                  {img && (
                    <Img
                      fluid={img}
                      alt=""
                      className="h-56 w-full object-cover lg:absolute lg:h-full"
                    />
                  )}
                </>
              )}
            </div>
          </div>
          <div className="relative pt-12 px-4 pt-8 lg:pt-0 sm:px-6 lg:px-8 lg:max-w-7xl lg:mx-auto lg:grid lg:grid-cols-2">
            <div className="lg:col-start-2 lg:pl-8">
              <div className="text-base max-w-prose mx-auto lg:max-w-lg lg:ml-auto lg:mr-0">
                <h2 className="leading-6 text-amber-600 font-semibold tracking-wide uppercase">
                  {title}
                </h2>
                <h3 className="text-3xl leading-8 font-extrabold tracking-tight text-blue-gray-900 sm:text-4xl">
                  {description}
                </h3>
                <div className="mt-5 prose prose-indigo text-gray-500 article">
                  <ReactMarkdown>{markdown.excerpt}</ReactMarkdown>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurProcessSection;
